import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateUtilsService } from 'core/utilities/date-utils.service';
import { UtilsService } from 'core/utilities/utils.service';
import {
  BehaviorSubject,
  catchError,
  Observable,
  Subject,
  throwError,
} from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { SearchFilter } from 'shared/models/search-filter';
import { ServerBlobResult } from 'shared/models/server-blob-result';
import { ConfigService } from './infrastructure/config.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  reportSubject: Subject<ReportFilters>;
  private defaultFilter: ReportFilters;
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  constructor(
    private dateUtils: DateUtilsService,
    private http: HttpClient,
    private configService: ConfigService,
    private utilService: UtilsService
  ) {
    const currentFy = this.dateUtils.getFyDetails();
    this.defaultFilter = {
      startDate: currentFy.startDate.toLocaleDateString(),
      endDate: currentFy.endDate.toLocaleDateString(),
      skip: 0,
      take: 10,
    };
    this.reportSubject = new BehaviorSubject<ReportFilters>(this.defaultFilter);
  }

  exportPartnerEngagement(filters: {
    startDate: string;
    endDate: string;
    organizationId: number;
    specialistId: number;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/partner-engagement-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportPartnerJobs(filters: {
    partnerIds: number[];
    specialistId: number;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/partner-jobs-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportPartnersAndSpousesHired(): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/partners-and-spouses-hired-export`;
    return this.http
      .get<ServerBlobResult>(url)
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportTeleworkRemote(filters: {
    organizationId: number;
    specialistId: number;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/telework-and-remote-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportCandidateStatusReport(filters: {
    organizationId: number;
    currentStatus: boolean;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/candidate-status-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportSpecialistSpouseEngagement(filters: {
    startDate: string;
    endDate: string;
    organizationId: number;
    employmentDetailId: number;
    getTotals: boolean;
    specialistId: number;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/specialist-spouse-engagement-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportSpecialistHotJobs(filters: {
    organizationId: number;
    specialistId: number;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/specialist-hot-jobs-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  exportSpecialistSpousesHired(filters: {
    startDate: string;
    endDate: string;
    organizationId: number;
    reportMetricId: number;
    getTotals: boolean;
    specialistId: number;
  }): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/specialist-spouses-hired-export`;
    const params = new HttpParams({ fromObject: filters });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  getPartnerEngagementReport(
    filters: ReportFilters
  ): Observable<ApiResponse<PartnerEngagementReport[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/partner-engagement`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<PartnerEngagementReport[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getPartnersAndSpousesHired(
    filters: ReportFilters
  ): Observable<ApiResponse<PartnersAndSpousesHired[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/partners-and-spouses-hired`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<PartnersAndSpousesHired[]>>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  getTeleworkRemoteReport(
    filters: ReportFilters
  ): Observable<ApiResponse<TeleworkRemote[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/telework-and-remote`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<TeleworkRemote[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getCandidateStatusReport(
    filters: ReportFilters
  ): Observable<ApiResponse<CandidateStatus[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/candidate-status`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<CandidateStatus[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getSpecialistHotJobsReport(
    filters: ReportFilters
  ): Observable<ApiResponse<SpecialistHotJobs[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/specialist-hot-jobs`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<SpecialistHotJobs[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getSpecialistSpouseEngagementReport(
    filters: ReportFilters
  ): Observable<ApiResponse<SpecialistSpouseEngagement[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/specialist-spouse-engagement`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<SpecialistSpouseEngagement[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getSpecialistSpousesHiredReport(
    filters: ReportFilters
  ): Observable<ApiResponse<SpecialistSpousesHired[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/specialist-spouses-hired`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<SpecialistSpousesHired[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  setReportFilters(reportFilters: ReportFilters): void {
    this.reportSubject.next(reportFilters);
  }

  partnerJobs(filters: ReportFilters): Observable<ApiResponse<PartnerJobs[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/reporting/partner-jobs`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<PartnerJobs[]>>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface PartnerEngagementReport {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  accountStatus: string;
  organizationName: string;
  lastLoginDate: string;
  userRoles: string[];
  totalSuccessfulLogins: number;
  totalCandidateSearches: number;
}

export interface PartnerJobs {
  partnerId: string;
  partnerName: string;
  hasFeed: boolean;
  isFeedEnabled: boolean;
  lastLogDate: Date;
  publishedJobCount: number;
  manualJobCount: number;
  totalJobCount: number;
  importedJobCount: number;
  lastFeedStatus: string;
  feedHasError: boolean;
}

export interface PartnersAndSpousesHired {
  date: Date;
  totalPartners: number;
  partnersAdded: number;
  spousesHired: number;
}

export interface ReportFilters extends SearchFilter {
  startDate?: string;
  endDate?: string;
  employmentDetailId?: number;
  organizationId?: number;
  reportMetricId?: number;
  specialistId?: number;
  getTotals?: boolean;
  currentStatus?: boolean;
}

export interface TeleworkRemote {
  partnerName: string;
  partnerSecoUrl: string;
  offersRemote?: boolean;
  offersTelework?: boolean;
  internationalFacilities?: boolean;
}

export interface CandidateStatus {
  partnerId: number;
  partnerName: string;
  candidateName: number;
  status: string;
  hireDate: string;
  lastUpdatedDate: string;
}

export interface SpecialistHotJobs {
  organizationName: string;
  jobTitle: string;
  publishStartDate: Date;
  isRemote: boolean;
  isTelework: boolean;
}

export interface SpecialistSpouseEngagement {
  organizationName: string;
  employmentDetail: string;
  spouseCount?: number;
  reportingPeriod: Date;
  createdDate: Date;
}

export interface SpecialistSpousesHired {
  organizationName: string;
  militaryService: string;
  spousesHired?: number;
  reportingPeriod: Date;
  createdDate: Date;
  createdBy: string;
  updatedBy: string;
}
